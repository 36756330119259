import dynamic from 'next/dynamic';
import { MEDIUM_MAX_WIDTH } from '@constants';
import { CONTENTFUL_BUTTON_EVENTS } from '@constants/contentful';
import { Button } from '@components/common';
import { ButtonProps } from '@components/common/Button';
import { handelize } from '@utils/strings';
import { ContentfulButtonFields } from '@ts/contentful';
import { useCartContext } from '@context';
import { useCart } from '@services/shopify';

const ContentfulButton = ({
	text,
	link,
	size,
	color,
	fullWidth = false,
	event = 'None',
	attentiveMobileId = undefined, // ONLY when event is 'Attentive'
	attentiveDesktopId = undefined, // ONLY when event is 'Attentive'
	price = undefined,
	variant = undefined, // ONLY when have price
	keyCart = undefined, // ONLY when have price
	valueCart = undefined, // ONLY when have price
}: ContentfulButtonFields & ButtonProps) => {
	const { isLoading: isCartLoading } = useCart();
	const { handleCartAdd, isCartMutating } = useCartContext();
	if (event === CONTENTFUL_BUTTON_EVENTS.VTO) {
		const DynamicVTOModal = dynamic(() => import('@components/common/VTOModal/VTOModal'));
		return <DynamicVTOModal text={text} color={color} size={size} />;
	}

	function handleTriggerAttentive() {
		// Desktop - Attentive Sign-Up Unit ID
		if (window.innerWidth > MEDIUM_MAX_WIDTH)
			return attentiveDesktopId && window.__attentive.trigger(null, null, null, attentiveDesktopId);

		// Mobile - Attentive Sign-Up Unit ID
		return attentiveMobileId && window.__attentive.trigger(null, null, null, attentiveMobileId);
	}

	return (
		<Button
			data-testid={`${handelize(`${link}`)}`}
			data-collection-button={`${handelize(`${link}`)}`}
			href={link}
			key={text}
			style={{ width: fullWidth ? '100%' : 'fit-content', alignSelf: 'center' }}
			size={size}
			color={color}
			withPrice={!!price}
			price={price}
			{...(fullWidth ? { fullWidth } : {})}
			{...(event === CONTENTFUL_BUTTON_EVENTS.ATTENTIVE ? { onClick: () => handleTriggerAttentive() } : {})}
			{...(price ? { onClick: () => {
				!isCartLoading && handleCartAdd(
					[
						{
							variant: { ...variant, collection: '' },
							customAttributes: [
								{
									key: keyCart,
									value: valueCart,
								},
							],
						},
					],
					true
				);
			},
			disabled: isCartMutating } : {})}
		>
			{text}
		</Button>
	);
};

export default ContentfulButton;
